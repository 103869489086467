
import BudgetsInvoicesSlipsService from "@/modules/budgets-invoices-slips/budgets-invoices-slips-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.create(payload).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    update(id,payload) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.update(id,payload).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.remove(id).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.delete(id).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.get(id).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesSlipsService.all().then(response => {
                resolve({
                    message: "BudgetsInvoicesSlips encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoicesSlips",
                    success: false,
                    error
                });
            })
        })
    }
}
