
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/budgets_invoices_slips/create", payload);
    },
    update(id, payload) {
        return axios.post(`v1_genesis/v1/budgets_invoices_slips/update/${id}`, payload);
    },
    remove(id) {
        return axios.delete(`v1_genesis/v1/budgets_invoices_slips/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_genesis/v1/budgets_invoices_slips/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_genesis/v1/budgets_invoices_slips/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/budgets_invoices_slips/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/budgets_invoices_slips/`);
    }
}
